:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

* {
    margin: 0;
    padding: 0;
}

body {
    // max-width: 1200px; 
    margin: 0 auto;
    font-weight: 300;
    color: #222222;
    font-family: 'poppins';
}



header {
    font-family: sans-serif;
    text-transform: uppercase;
    margin-top: 48px;
    text-align: left;
    line-height: normal;
}

#header-img {
    // max-width: var(--maxWidth);
    margin: auto;
}

#header-img img {
    max-height: 140px;

}

#header-title {
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
    text-decoration: none;
}

#header-subtitle {
    font-size: 20px;
    text-transform: none;
    padding: 0;
    margin: 0;
}

// NAVIGATION AND MENUS

nav {
    margin-bottom: 36px;
    background-color: var(--orange);
    padding-top: 16px;
    padding-bottom: 16px;
    max-width: none;
}

nav ul {
    margin: auto;
    list-style: none;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    color: var(--veryLightGray);
}

nav li {
    margin-right: 24px;
}

nav a {
    color: var(--veryLightGray);
    text-decoration: none;
}


h1 {
    // font-family: 'Roboto', sans-serif;
    color: var(--varyDarkBlue)
}

h2 {
    // font-family: 'Roboto', sans-serif;
    margin-top: 36px;
    color: var(--varyDarkBlue);
}

h3 {
    font-size: 24px;
    margin-top: 4rem;
}

table {
    margin-top: 24px;
}


td {
    padding-bottom: 16px;
}

tr td:first-child {
    padding-right: 32px;
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.gdpr h3 {
    margin-top: 36px;
}


p {
    // font-family: 'Noto Serif', serif;
    margin-top: 1rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    font-size: 1.8rem;

}

// LINKS
a {
    color: rgb(133, 132, 132);
}

a:visited {
    color: rgb(133, 132, 132);
}

a:hover {
    text-decoration: none;
    color: black;
}

.current {
    color: black !important;
}

.no-hover {
    color: black !important;
}

#info {
    // background-color: var(--veryLightGray);
    // background-image: url("../images/layered-waves-haikei.svg");
}

#info h1 {
    border-bottom: 12px solid --orange;
}

.info-content {
    padding-top: 400px;
}

.spacer {
    
    aspect-ratio: 960/540;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#forsakringar {
    text-align: center;
    padding-top: 32px;
    background-color: #f2f2f2;
    // background-color: var(--varyDarkBlue);
    // color: var(--veryLightGray);
}
#forsakringar h2 {
    text-align: center;
    // color: var(--veryLightGray);
}


// FLEXBOX SETTINGS
.flex-container {
    display: flex;
    justify-content: space-between;
}



.vertical {
    flex-direction: column;
}

@media screen and (max-width: 750px) {
    .flex-container {
        flex-direction: column;
    }

    .vertical {
        flex-direction: row;
    }
}






ul {
    list-style-type: disc;
    font-weight: 200;
    margin-left: 24px;
}


img {
    max-width: 100%;
}

// Footer
footer {
    padding: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    margin: 0 auto;
    margin-top: 64px;
    background-color: var(--varyDarkBlue);
    color: #e7e3e3;
    text-align: center;
}

footer p {
    font-size: 16px;
    width: 100%;
}

footer a {
    font-size: 16px;
    color: #e7e3e3;

}

span {
    text-transform: none;
    font-style: normal;
}

strong {
    font-weight: 800;
    color: black;
}

.span-contact {
    font-size: 22px;
    color: var(--varyDarkBlue);
    font-weight: 400;
    -webkit-transform: translate3d(0,0,0) !important;
    transform: translate3d(0,0,0) !important;
}


/* CSS */
.button-24 {
  margin-top: 6px;
  width: 100%;
  background: #FF4742;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 10px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.button-24:active {
  opacity: .5;
}



.card {
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-top: 3rem;
    margin-bottom: 3rem;

}

.card h3 {
    font-family: "poppins";
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 30px;
}

.card a {
    font-size: 18px;
    text-decoration: none;
    display: inline-block;
}
.card a:visited {
    color: #FFFFFF;
}
.card a:hover {
    color: #FF4742;;
}

.cyan {
    border-top: 3px solid var(--cyan);
}

.red {
    border-top: 3px solid var(--red);
}

.blue {
    border-top: 3px solid var(--blue);
}

.orange {
    border-top: 3px solid var(--orange);
}

.gray {
    // border-top: 12px solid var(--grayishBlue);
}

.curve {
    position: absolute;
    height: 250px;
    width: 100%;
    bottom: 0;
    text-align: center;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    transform: translate(85%, 60%);
    background-color: hsl(216, 21%, 16%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: #3c31dd;
    transform: translate(-4%, 40%);
    z-index: -1;
}